<template>
  <v-container fluid class="fluid">
    <h1 class="text-left product-title" v-html="$t('masterdata_new_farm_header')"></h1>

    <v-layout row justify-center>
      <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-layout>

    <v-form ref="createfarmsform" id="createfarms">
      <v-row>
        <v-col cols="12" xs="12" md="3" xl="4">
          <!-- Betriebsform -->
          <v-select
            v-model="farms.farmType.id"
            :label="$t('masterdata_farms_dairy_type_id')"
            :items="farmTypes"
            :item-title="this.$getLangKey()"
            item-value="id"
            :rules="[rules.required]"
          />
          <!-- Canton ID -->
          <v-select
            id="cantonNo"
            v-model="farms.canton.id"
            :label="$t('masterdata_farms_canton_no')"
            :items="cantonsCH"
            :item-title="this.$getLangKey()"
            item-value="id"
            :rules="[rules.required]"
          />
          <!-- Produktionsgebiet -->
          <!--        todo production stores müssen noch definiert werden-->
          <v-select
            id="productionArea"
            v-model="farms.productionArea.id"
            :label="$t('masterdata_farms_production_area')"
            :items="production"
            :item-title="this.$getLangKey()"
            item-value="id"
            :rules="[rules.required]"
          />
          <!-- Produktionszone -->
          <v-select
            id="productionZone"
            v-model="farms.productionZone.id"
            :label="$t('masterdata_farms_production_zone')"
            :items="production"
            :item-title="this.$getLangKey()"
            item-value="id"
            :rules="[rules.required]"
          />
          <!-- productionType -->
          <v-select
            id="productionType"
            v-model="farms.productionType.id"
            :label="$t('masterdata_farms_productionType')"
            :items="production"
            :item-title="this.$getLangKey()"
            item-value="id"
            :rules="[rules.required]"
          />
          <!-- ÖLN - ecologicalPerformanceRecord-->
          <v-switch
            id="epr"
            v-model="farms.ecologicalPerformanceRecord"
            :label="$t('masterdata_farms_ecologicalPerformanceRecord')"
            color="info"
            :rules="[rules.required]"
          />
          <!-- Voraussetzung Sömmerung -->
          <v-switch id="alpinePasture" v-model="farms.alpinePasture" :label="$t('masterdata_farms_alpinePasture')" color="info" />
          <!-- silo -->
          <v-switch id="silo" v-model="farms.silo" :label="$t('masterdata_farms_silo')" color="info" />
          <!-- Bio -->
          <v-switch id="organic" v-model="farms.organic" :label="$t('masterdata_farms_organic')" color="info" />
          <!-- milkQualityTesting -->
          <v-switch v-model="farms.milkQualityTesting" id="milkQualityTesting" :label="$t('masterdata_farms_milkQualityTesting')" color="info"></v-switch>
          <!-- Agis Clearing -->
          <v-switch v-model="farms.agisClearing" id="agisClearing" :label="$t('masterdata_farms_agisId_fiktiv')" color="info"></v-switch>
          <!-- Casalp -->
          <v-switch v-model="farms.casalp" id="casalp" :label="$t('masterdata_farms_casalp')" color="info"></v-switch>
          <!-- Bom -->
          <v-switch v-model="farms.bom" id="bom" :label="$t('masterdata_farms_reporting_obligation_BOM')" color="info"></v-switch>
          <!-- Market Stabilisation -->
          <v-switch v-model="farms.marketStabilisation" id="marketStabilisation" :label="$t('masterdata_farms_marketStabilisation')" color="info"></v-switch>
          <!-- SMP -->
          <v-switch v-model="farms.smp" id="smp" :label="$t('masterdata_farms_smp')" color="info"></v-switch>
        </v-col>
      </v-row>

      <v-btn class="m-2" color="primary" @click="onSubmit"><span v-html="$t('masterdata_farms_save')" /></v-btn>
      <v-btn class="m-2" color="secondary" :to="{ name: 'masterdata_farms_search' }" @click="reset">
        <span v-html="$t('masterdata_farms_cancel')" />
      </v-btn>
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { apiURL } from '@/main'
import { showError } from '@/services/axios'

import { useCantonsStore } from '@/store/enums/CantonsStore'
import { useFarmTypesStore } from '@/store/enums/FarmTypesStore'

const farmDataDefault = {
  farmType: { id: 0 },
  productionArea: { id: 0 },
  productionZone: { id: 0 },
  productionType: { id: 0 },
  canton: { id: 0 },
  ecologicalPerformanceRecord: false,
  alpinePasture: false,
  silo: false,
  organic: false,
  milkQualityTesting: false,
  agisClearing: false,
  casalp: false,
  bom: false,
  smp: false,
  marketStabilisation: false
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'farmsCreate',
  data() {
    return {
      farms: farmDataDefault,
      rules: {
        required: (value) => !!value || this.$t('form_field_required')
      },
      loading: false,
      production: [{ id: 99, nameDe: 'TBD1', nameFr: '', nameIt: '' }]
    }
  },
  computed: {
    cantonsCH() {
      return useCantonsStore().CantonsCH
    },
    farmTypes() {
      return useFarmTypesStore().farmTypes
    }
  },
  methods: {
    reset() {
      this.$refs['form'].reset()
    },
    async onSubmit() {
      if (!(await this.$refs.createfarmsform.validate()).valid) {
        return false
      }
      this.loading = true
      try {
        const response = await this.axios.post(
          apiURL + '/farms',
          {
            ...this.farms
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await response) {
          const data = await response.data
          this.$router
            .push({
              name: 'masterdata_farms_edit',
              params: { id: data.id, tab: 'overview' }
            })
            .catch((e) => {
              /* duplicated route */
            })
          this.reset()
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    beforeMount() {
      useFarmTypesStore().fetchFarmTypes()
      useCantonsStore().fetchCantonsCH()
    }
  }
})
</script>
